import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	InputProps,
} from '@chakra-ui/react';
import { useTsController } from '@ts-react/form';
import React from 'react';

import { BaseFieldProps } from '@/components/ts_form/types/base_field_props';
import BaseText from '@/components/utils/text/BaseText';

interface TextFieldProps extends BaseFieldProps {
	inputProps?: InputProps | undefined;
	paddingTop?: string;
}

/**
 * Text Field Component
 *
 * @param {TextFieldProps} {
 * 	label,
 * 	inputProps,
 * 	isDisabled,
 * 	isRequired,
 * 	paddingTop,
 * }
 * @return {*}
 */
const TextField = ({
	label,
	inputProps,
	isDisabled,
	isRequired,
	paddingTop,
}: TextFieldProps) => {
	const {
		field: { onChange, value },
		error,
	} = useTsController<string>();

	return (
		<FormControl isInvalid={!!error} pt={paddingTop ?? 1}>
			<FormLabel>{label}</FormLabel>
			<BaseText
				value={value ?? ''}
				onChange={onChange}
				inputProps={inputProps}
				isDisabled={isDisabled}
				isRequired={isRequired}
			/>
			<FormErrorMessage>{error?.errorMessage}</FormErrorMessage>
		</FormControl>
	);
};

export default TextField;
