import { Input, InputProps } from '@chakra-ui/react';
import React from 'react';

interface BaseTextProps {
	value: string;
	onChange: (value: string) => void;
	inputProps?: InputProps | undefined;
	isDisabled?: boolean;
	isRequired?: boolean;
}

/**
 * Text BaseText Component
 *
 * @param {BaseTextProps} {
 * 	label,
 * 	inputProps,
 * 	isDisabled,
 * 	isRequired,
 * 	paddingTop,
 * }
 * @return {*}
 */
const BaseText = ({
	value,
	onChange,
	inputProps,
	isDisabled,
	isRequired,
}: BaseTextProps) => {
	return (
		<Input
			{...inputProps}
			w="100%"
			disabled={isDisabled !== undefined && 'true'}
			onChange={(event) => {
				onChange(event.target.value);
			}}
			value={value ?? ''}
			required={isRequired}
			type="text"
			color="gray.700"
			_hover={{ borderColor: 'green.700' }}
		/>
	);
};

export default BaseText;
