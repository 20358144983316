import { Button, Flex, FormLabel, Stack, Switch, Text } from '@chakra-ui/react';
import { createTsForm } from '@ts-react/form';
import { ReactNode } from 'react';
import { z } from 'zod';

import TextField from '@/components/ts_form/text/TextField';
import BaseLink from '@/components/utils/links/BaseLink';

const mapping = [[z.string(), TextField] as const] as const;

interface FormContainer {
	onSubmit: () => void;
	children: ReactNode;
	loading?: boolean;
	isDisable?: boolean;
}

const FormContainer = ({ onSubmit, children, loading }: FormContainer) => {
	return (
		<>
			<form onSubmit={onSubmit}>
				<Stack>{children}</Stack>
				<Flex py={5} align="center" justify="space-between">
					<Flex>
						<Switch id="email-alerts" pr="2" colorScheme="green" />
						<FormLabel htmlFor="email-alerts" mb="0">
							Ingat komputer ini
						</FormLabel>
					</Flex>
					<BaseLink href="/forgot-password">
						<Text
							fontWeight={400}
							fontSize="12px"
							decoration="underline"
							color="green.600"
							textAlign="right"
						>
							Lupa NIK atau Password?
						</Text>
					</BaseLink>
				</Flex>
				<Button
					mt={4}
					width="full"
					colorScheme="green"
					type="submit"
					isLoading={loading}
				>
					Masuk
				</Button>
			</form>
		</>
	);
};

const LoginForm = createTsForm(mapping, {
	FormComponent: FormContainer,
});

export default LoginForm;
