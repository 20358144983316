import { Box, Flex, Text } from '@chakra-ui/react';
import * as React from 'react';

import LoginForm from '@/components/login/LoginForm';
import { loginSchema } from '@/components/login/LoginSchema';
import BaseLink from '@/components/utils/links/BaseLink';

export interface LoginMainProps {}

/**
 * Login Main
 * @date 23/08/2023 - 07:10:25
 *
 * @returns {*}
 */
const LoginMain: React.FC<LoginMainProps> = () => {
	// eslint-disable-next-line unicorn/consistent-function-scoping
	const handleLoginSubmit = () => {};

	return (
		<>
			<Flex
				direction="column"
				align={['center', 'start']}
				mt={['72px', '100px']}
				gap={2}
				w={['full', '354px']}
				mx="auto"
			>
				<Text color="green.600" fontSize={['28px', '32px']} fontWeight={700}>
					Selamat Datang
				</Text>
				<Text
					color="gray.600"
					fontSize={['12px', '14px']}
					fontWeight={700}
					align={['center', 'start']}
				>
					Masukkan email dan password untuk masuk
				</Text>
				<Box mt={['10px', '24px']} w="full">
					<LoginForm
						schema={loginSchema}
						onSubmit={handleLoginSubmit}
						defaultValues={{
							email: '',
							password: '',
						}}
						props={{
							email: {
								label: 'NIK',
								inputProps: {
									placeholder: 'Ketikkan NIK',
								},
							},
							password: {
								label: 'Password',
								inputProps: { placeholder: 'Ketikkan password' },
							},
						}}
					/>
					<Flex
						direction={['column', 'row']}
						py={5}
						align="center"
						justify={['start', 'center']}
					>
						<Text fontWeight={400} fontSize="14px" color="gray.600" mr={2}>
							Belum punya akun?
						</Text>
						<BaseLink href="/aktivasi">
							<Text
								fontWeight={700}
								fontSize="14px"
								color="green.600"
								decoration="underline"
							>
								Aktivasi Akun e-RKAM disini!
							</Text>
						</BaseLink>
					</Flex>
				</Box>
			</Flex>
		</>
	);
};

export default LoginMain;
